@import '../../styles/variables';

.container {
  width: calc(50% - 20px);
  max-width: 100%;
  margin: 0px 0px 20px 0px;
  label {
    display: block;
    margin-bottom: 10px;
    color: white;
    font-size: 12px;
    text-align: left;
  }
  .slider {
    width: calc(100% - 10px);
    margin-left: 10px;
  }
}