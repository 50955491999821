@import '../../styles/variables';

.container {
  position: absolute;
  right: 30px;
  top: 30px;
  width: calc(100vw - 320px);
  display: flex;
  flex-direction: column;
  background-color: $primary-dark;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 3px 8px;
  z-index: 2;
  opacity: 0;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  @include transition;
  @media only screen and (min-width: $lg-screen) {
    flex-direction: row;
  }
  &.active {
    opacity: 1;
  }
  &.panel-is-open {
    width: calc(50vw - 60px);
    @media only screen and (min-width: $lg-screen) {
      flex-direction: column;
    }
    .search {
      .label {
        display: none;
      }
      @media only screen and (min-width: $lg-screen) {
        width: 100%;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        border-top-right-radius: 0px;
      }
    }
  }
  .reset {
    position: absolute;
    left: 0px;
    top: -23px;
    button {
      @include btn;
      text-decoration: underline;
      background-color: $black;
      color: $white;
      width: 110px;
      height: 24px;
      padding: 5px 8px 5px 8px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 12px;
      z-index: 1;
    }
  }
  .select-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    z-index: 3;
    padding: 10px 20px;
    @include transition;
    &.advenced {
      max-height: calc(100vh - 60px);
      padding: 20px 20px 160px 20px;
      overflow-y: auto;
      &.panel {
        padding: 20px 20px 150px 20px;
        max-height: calc(100vh - 95px);
      }
    }
    // overflow-x: hidden;
    @include scrollbarDark;
    .row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &.border {
        margin-top: 10px;
        border-top: 1px dashed rgba(255, 255, 255, 0.22);
        padding-top: 10px;
      }
      .select {
        margin-bottom: 10px;
        width: calc(50% - 10px);
        &:nth-child(odd) {
          margin-right: 20px;
        }
        &.no-panel {
          width: calc(33.33% - 10px);
          &:nth-child(even) {
            margin: 0px 15px;
          }
          &:nth-child(odd) {
            margin-right: 0px;
          }
        }
        // @media only screen and (min-width: $md-screen) {
        //   width: calc(33.33% - 10px);
        //   &:nth-child(even) {
        //     margin: 0px 15px;
        //   }
        // }
        // // width: calc(50% - 10px);
        // margin-bottom: 10px;
        // &:nth-child(odd) {
        //   margin-right: 20px;
        // }
        label {
          font-size: 12px;
          display: block;
          margin-bottom: 5px;
          color: $white;
        }
      }
    }
  }
  .ranges {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .search {
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    background-color: $black;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: $lg-screen) {
      width: 150px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: $radius;
      border-top-right-radius: $radius;
      border-top-left-radius: 0px;
    }
    .label {
      color: $white;
      margin: 5px;
      display: none;
      @include ExtraBold;
      @media only screen and (min-width: $lg-screen) {
        display: block;
      }
    }
    .icon {
      width: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      @include transition;
      &.open {
        transform: rotate(45deg);
      }
      img {
        width: 100%;
      }
    }
  }
}