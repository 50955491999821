.container {
  width: 100%;
  height: 100%;
  min-height: calc(30vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  &.hide {
    display: none;
  }
  p {
    text-transform: uppercase;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotating 2s linear infinite;
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}