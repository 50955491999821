@import '../../styles/variables.scss';

.container {
  margin-bottom: 20px;
  background-color: $white;
  border-radius: $radius;
  @include transition;
  .title {
    display: flex;
    padding: 10px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 12px;
      margin: 0px 10px;
      text-transform: uppercase;
    }
    .toggle {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      width: 28px;
      height: 28px;
      border-radius: 15px;
      &:hover {
        background-color: rgba($primary-color, 0.2);
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .section-content {
    @include transition;
    padding-bottom: 0px;
    .content {
      height: 30vh;
      min-height: 30vh;
      width: 100%;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
    }
  }
}