@import '../../styles/variables';

.container {
  height: 100%;
  .logo {
    position: absolute;
    top: 60px;
    left: 80px;
  }
  .dashboard {
    position: absolute;
    width: 100%;
    margin-top: 180px;
    height: calc(100% - 180px);
    padding: 30px 30px 0px 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    @include scrollbar;
    opacity: 0;
    &.active {
      opacity: 1;
    }
    &.open {
      width: 50%;
      left: 50%;
      margin-top: 220px;
      height: calc(100% - 220px);
      padding: 40px 20px 30px 30px;
      .row {
        flex-direction: column;
        .col-2, .col-3 {
          width: 100%;
        }
      }
    }
    .row {
      display: flex;
      justify-content: space-between;
      .col-2 {
        width: calc(50% - 15px);
        overflow-y: hidden;
      }
      .col-3 {
        width: calc(33.33% - 20px);
      }
    }
  }
}