@import '../../styles/variables';

.shankey {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $white;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.8);
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($black, 1);
  }
  rect, path , text {
    @include transition;
    animation: fade 0.2s forwards;
  }
  rect {
    cursor: pointer;
  }
  path {
    cursor: pointer;
  }
  @keyframes fade { 
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  .tooltip {
    pointer-events: none;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 10;
    @include box;
    padding: 5px 10px;
    @include transition;
    min-width: 260px;
    background-color: $white;
    p {
      color: rgba($dark, 0.5);
      margin: 0px;
      font-size: 12px;
      @include Bold;
      span {
        text-transform: uppercase;
      }
    }
  }
}