@import '../../styles/variables';

.container {
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 0px;
    .title {
      h3 {
        @include ExtraBold;
        margin: 0px;
      }
      p {
        color: rgba($dark, 0.7);
        margin: 0px;
      }
    }
    .toggle {
      @include btn;
      color: $dark;
      width: 30px;
      height: 30px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      background-color: rgba($primary-color, 0);
      &:hover {
        background-color: rgba($primary-color, 0.3);
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  a {
    color: $dark;
    display: flex;
    align-items: center;
  }
  .panel {
    overflow: hidden;
    @include transition;
    .label {
      @include ExtraBold;
    }
    .list {
      margin: 0px 0px 10px 0px;
    }
  }
  border-bottom: 1px solid rgba(37, 77, 144, 0.08);
}