@import '../../styles/variables';

.pie {
  transform: translateY(-10px);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  animation: fade 0.2s forwards;
  display: flex;
  justify-content: center;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $white;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.8);
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($black, 1);
  }
  @keyframes fade { 
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  path {
    cursor: pointer;
  }
  svg {
    text, rect {
      opacity: 1;
      pointer-events: none;
    }
    &.zoomed {
      text, rect {
        opacity: 0;
      }
      .label {
        opacity: 0;
      }
    }
  }
  .back {
    position: absolute;
    left: 0px;
    @include btn;
    @include text;
    text-transform: uppercase;
    font-size: 12px;
    color: $primary-color;
    background-color: #bdd5fa;
    border-radius: 20px;
    padding: 5px 10px 5px 5px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #9fbeeb;
    }
  }
  .tooltip {
    pointer-events: none;
    top: 0px;
    left: 0px;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 10;
    padding: 5px 10px;
    @include transition;
    width: 100%;
    p {
      margin: 0px;
      font-size: 12px;
      @include Bold;
      text-align: center;
      word-break: all;
      width: 150px;
      text-shadow: $white 1px 0 15px;
      .value {
        font-size: 32px;
      }
      span {
        text-transform: uppercase;
      }
    }
  }
}