@import './variables.scss';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  color: $dark;
  font-family: "Ubuntu-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  box-sizing: border-box;
  @include text;
}

* {
  box-sizing: border-box;
}

.App {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  .mobile {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.bold {
  @include Bold;
}

.text-primary {
  color: $primary-color;
}

.rc-slider-mark-text {
  width: 30px;
  font-weight: bold;
}

.rc-slider-rail {
  background-color: rgba($white,0.3) !important;
  height: 8px !important;
}

.rc-slider-dot {
  display: none !important;
}

.rc-slider-mark-text {
  color: rgba($white,0.3) !important;
}


.rc-slider-track {
  background-color: $primary-light !important;
  height: 8px !important;
}

.rc-slider-handle {
  margin-top: -3px !important;
  border: solid 2px $primary-light !important;
  box-shadow: 0 0 0 0px $primary-light !important;
} 

.rc-slider-handle-dragging {
  box-shadow: 0 0 0 2px $primary-light !important;
} 

.rc-slider-tooltip-inner {
  padding: 1px 6px !important;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: $white !important;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  background-color: transparent !important;
  border-radius: 6px;
  box-shadow: 0 0 4px transparent !important;;
}