@import '../../../styles/variables.scss';

.container-select{

  label{
    font-size: 14px;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.select{
      margin-top: 10px;
    }
  }

  .select{
    width: 100%;
    min-width: 100%;
    &.border {
      border: 1px solid rgba(37, 77, 144, 0.19);;
      border-radius: 6px;
    }
  }

}
