@mixin title-1 {
  font-family: "Nunito-Regular";
  font-weight: 700;
  font-size: 28px;
}

@mixin title-2 {
  font-family: "Nunito-Bold";
  font-weight: 700;
  font-size: 18px;
}

@mixin title-3 {
  font-family: "Nunito-Bold";
  font-weight: 700;
  font-size: 16px;
}

@mixin text {
	font-family: "Nunito-Regular";
  font-weight: 400;
  font-size: 14px;
}

@mixin Bold {
  font-family: "Nunito-Bold";
  font-weight: 700;
}

@mixin SemiBold {
  font-family: "Nunito-SemiBold";
}

@mixin ExtraBold {
  font-family: "Nunito-ExtraBold";
  font-weight: 800;
}


@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin btn {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

@mixin form-group {
  margin-bottom: 20px;
}

@mixin btn-primary {
  @include btn;
  @include title-3;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary-color;
  padding: 0px 12px;
  border-radius: 16px;
  color: $primary-color;
  text-decoration: none;
  &:hover {
    background-color: $primary-color;
    color: $white;
    p {
      color: $white;
    }
  }
  &.white {
    border: 1px solid $white;
    background-color: $primary-color;
    color: $white;
    &:hover {
      background-color: rgba($white, 0.2);
    }
    p {
      color: $white;
    }
  }
  p {
    margin: 0px;
    text-decoration: none;
    @include transition;
  }
  svg {
    margin-left: 0px;
  }
}

@mixin box {
  background: rgba($dark, 0.0);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 3px 8px;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.2);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($primary-color, 0.5);
  }
}

@mixin scrollbarDark {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.8);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($black, 1);
  }
}