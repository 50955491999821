@import '../../styles/variables';

.container {
  position: absolute;
  top: 30px;
  left: -1px;
  z-index: 3;
  width: calc(50% - 40px);
  height: calc(100% - 30px);
  transform: translateX(-100%);
  @include transition;
  &.open {
    transform: translateX(0px);
  }
  .content {
    position: relative;
    height: 100%;
    background-color: $white;
    .close {
      @include btn;
      position: absolute;
      right: 0px;
      background-color: $dark;
      @include ExtraBold;
      padding: 15px 45px;
      color: $white;
      border-bottom-left-radius: 50px;
    }
    .toggle {
      @include btn;
      position: absolute;
      width: 51px;
      padding: 20px 10px;
      right: -51px;
      background-color: $dark;
      @include ExtraBold;
      color: $white;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      .count {
        border-top: 1px solid rgba($white, 0.3);
        margin-top: 20px;
        padding-top: 20px;
        p {
          @include text;
          margin: 0px;
          font-size: 12px;
          opacity: 0.3;
        }
      }
    }
    .help {
      @include btn;
      font-size: 12px;
      position: absolute;
      width: 51px;
      padding: 10px 10px;
      top: 140px;
      right: -51px;
      background-color: #50B7B8;
      color: $white;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      p {
        @include text;
        margin: 0px;
        font-size: 12px;
        opacity: 0.3;
      }
    }
    header {
      padding: 10px 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon {
        background-color: rgba($primary-color, 0.2);
        width: 47px;
        height: 47px;
        border-radius: 30px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h2 {
        @include ExtraBold;
        font-size: 30px;
        margin: 0px;
      }
      p {
        @include ExtraBold;
        margin-left: 10px;
        margin-top: 25px;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
    .search {
      display: flex;
      justify-content: space-between;
      padding: 0px 30px 10px 30px;
      .select {
        width: calc(50% - 10px);
        label {
          @include Bold;
        }
      }
    }
    .list {
      height: calc(100vh - 180px);
      overflow-y: auto;
      padding: 20px 30px;
      @include scrollbar;
    }
  }
}